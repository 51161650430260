import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import React, { useMemo, useState } from 'react';
import Filter from 'elements/controls/Filter';
import { isFullArray } from 'funcs/isFullArray';
import { Toast, ToastBody } from 'reactstrap';
import { capitalise } from 'funcs/capitalise';
import { isFullObject } from 'funcs/isFullObject';
import { filtersType } from 'types/filtersType';

// TODO - ADD multiple graphs views
// TODO - ADD download graph feature

type statType = { name: string; value: number; unit: string; }
type dataType = Record<string, string | number | Date>[];

type barGraphType = {
  data: dataType;
  expanded: boolean;
  isAnimated?: boolean;
  hasStats: boolean;
  unit: string;
  col: { x: string, y: string };
  filters?: filtersType;
  interval?: simpleStateType;
  barColor: string;
}

export const BarGraph = (props: barGraphType) => {

  const data = props.data;
  if (props.col.x === 'datetime' && isFullArray(data)) { // scuffed inline sort to reverse top slice of datetime desc sorted data
    data.sort((a, b) => (new Date(a.datetime)).getTime() - (new Date(b.datetime)).getTime());
  };
  const filters = props.filters;

  const [toast, setToast] = useState({ text: '', open: false });

  const stats:statType[] = useMemo(() => {
    if (isFullArray(data)) {
      const values = data.map(row => row.consumption);
      const sum = Math.round((values as number[]).reduce((a, b) => a + b)); // needed by mean calc as well
      return ([
        { name: 'Max', value: Math.round(Math.max(...(values as number[]))), unit: ' m³' },
        { name: 'Sum', value: sum, unit: ' m³' },
        { name: 'Mean', value: Math.round(sum / values.filter(val => val !== 0).length), unit: ' m³' },
        { name: 'Median', value: Math.round(values[Math.round(values.length / 2)] as number), unit: ' m³' }
      ]);
    }
  }, [data])

  const StatsBlock = () =>
    <div className={'input-group m-0 text-center ' + (props.expanded ? 'mb-1' : 'my-1')}>
      {stats.map((stat: statType) =>
        <div key={stat.name} className={'col btn p-0 btn-dark connect-top border-top-0' + (props.expanded ? '' : ' no-hover')}
          onClick={() => {
            props.expanded && navigator.clipboard.writeText(stat.value.toString()).then(() => {
              setToast({ text: stat.name, open: true });
              setTimeout(() => setToast(prev => ({ ...prev, open: false })), 3000);
            })
          }}>
          <p className='mb-0'>{stat.name}</p>
          <b>{stat.value} {stat.unit}</b>
        </div>
      )}
    </div>;

  const StatsToast = () =>
    <Toast isOpen={toast.open} className='toast'>
      <ToastBody className='text-center'>{capitalise(toast.text)} has been copied to clipboard</ToastBody>
    </Toast>

  return (
    <>
      <div className='no-select g-3 mt-2'>
        <div className='row m-0'>
          <ResponsiveContainer className='p-0' minWidth={100} minHeight={200}>
            {isFullArray(data) &&
              <BarChart data={data} style={{ cursor: !props.expanded && 'pointer' }} margin={{ left: 15 }}>
                {props.expanded && <rect width='100%' height='100%' fill='white' />}
                <XAxis dataKey={props.col.x} stroke='black' interval='preserveStart'/>
                <YAxis stroke='black' dataKey={props.col.y} />
                <text x='0%' y='10%'>{props.unit}</text>
                <Bar isAnimationActive={props.isAnimated} dataKey='consumption'
                  fill={typeof props.barColor === 'string' ? props.barColor : 'black'} />
              </BarChart>
            }
          </ResponsiveContainer>
        </div>
      </div>
      {props.expanded && <Filter type='graph' filters={filters} interval={props.interval} />}
      {isFullObject(stats) && props.hasStats && <><StatsToast /><StatsBlock /></>}
    </>
  );
}