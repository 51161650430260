import { useMutation, useQueryClient } from '@tanstack/react-query';
import xhr from 'ajax/xhr';

export const useUpdateSettings = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (setting:{sid:string, group:string, name:string, value:string|number}) => {
      const params = `sid=${setting.sid}&group=${setting.group}&name=${setting.name}&value=${setting.value}`;
      return xhr(params,'update/settings');
    },
    onSuccess: (data, setting) => {
      queryClient.invalidateQueries({queryKey: ['settings', setting.sid]});
    }
  });
}