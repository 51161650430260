import { capitalise } from 'funcs/capitalise';
import Funnel from 'img/Funnel';
import React, { useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { keyExists } from 'funcs/keyExists';
import { useLocation } from 'react-router-dom';
import { filtersType } from 'types/filtersType';
import { usePrimaryColor } from 'hooks/state/usePrimaryColor';
import whiteIfDark from 'funcs/whiteIfDark';
import { isFullObject } from 'funcs/isFullObject';
import { isFullArray } from 'funcs/isFullArray';

export default function Filter(props: { interval: simpleStateType, filters: filtersType, type: 'graph' | 'table' }) {

  const type = props.type;
  const filters = props.filters;
  const interval = props.interval;

  const location = useLocation();
  const path = location.pathname;

  const primaryColor = usePrimaryColor();

  const [filterColor, setFilterColor] = useState<string>();
  const [modal, setModal] = useState(true);
  const toggleModal = () => setModal(!modal);

  const FilterButton = (props: { column:string, row:string|number; }) =>
    <button key={props.column+props.row} className={'col btn btn-outline-dark' + (props.column === 'interval' ? ' connect-top' : '')}
      style={{
        background: (isFullObject(filters.selected[props.column]) && filters.selected[props.column][props.row]) || 
          (props.column === 'interval' && interval.value === props.row) ? primaryColor : 'white',
        color: (isFullObject(filters.selected[props.column]) && filters.selected[props.column][props.row]) || 
        (props.column === 'interval' && interval.value === props.row) ? whiteIfDark(primaryColor) : 'black'
      }}
      onClick={() => props.column === 'interval' ? interval.update(props.row) : filters.update(props.column, props.row)}>
      {props.row}
    </button>

  const DateSelect = (props: { startDate: Date, endDate: Date, type: string }) =>
  { const isMin = props.type === 'min' 
    return <DatePicker
      key={'dp' + capitalise(props.type) + 'Date'}
      className={'text-center form-control w-100 border-dark' + (isMin ? ' connect-bottom border-bottom-0' : ' connect-top') +
        (path.includes('irrigation') ? ' connect-bottom border-bottom-0' : '')}
      selected={isMin ? props.startDate : props.endDate}
      showTimeInput
      showIcon
      timeInputLabel='Time:'
      dateFormat='dd/MM/yyyy, hh:mm aa'
      onChange={(newDate: Date) => filters.update('datetime', props.type, 
        newDate.toLocaleDateString('en-CA', {hour:'2-digit', minute:'2-digit', second:'2-digit', hour12: false}).replace(',',''))}
      startDate={props.startDate}
      endDate={props.endDate}
      minDate={isMin ? null : props.startDate}
      selectsStart={isMin ? true : false}
      selectsEnd={isMin ? false : true}
      withPortal
    />;
  }
  
  const NumberInput = (props: { column: string, value: number, type: string }) => <input type='number' className='form-control text-center' defaultValue={props.value}
    onBlur={(e) => filters.update(props.column, props.type, parseFloat(e.target.value))} />

  const FilterModal = () =>
    <Modal key={path} isOpen={modal} fade={false}>
      <ModalHeader toggle={toggleModal} className='no-select'>
        {capitalise(type)} Filter
      </ModalHeader>
      <ModalBody>
        {isFullObject(filters.map) && Object.entries(filters?.map)?.map(([column, values]) =>
          <div key={'main'+column}>
            <label className='mt-3 no-select'><b>{capitalise(column) + (column === 'datetime' ? ' Range' : '')}</b></label>
            <div className={'w-100 ' + (column === 'datetime' ? '' : 'input-group') /* input-group very slightly offsets datepickers in a gross way */}>
              {keyExists('min', values) ?
                column === 'datetime' ?
                  <>
                    {['min', 'max'].map((type) => <DateSelect key={column+type} type={type}
                      startDate={new Date(Date.parse(typeof filters?.selected?.datetime?.min === 'string' ? filters.selected.datetime.min : values['min']))}
                      endDate={new Date(Date.parse(typeof filters?.selected?.datetime?.max === 'string' ? filters.selected.datetime.max : values['max']))} />)}
                    {path.includes('irrigation') &&
                      <div className='input-group w-100'>
                        {['Hourly', 'Daily', 'Monthly', 'Yearly'].map((text: string) => <FilterButton key={'dp' + text} column={'interval'} row={text} />)}
                      </div>
                    }
                  </>
                  :
                  <>
                    <NumberInput key={column+'min'} column={column} value={keyExists(column, filters.selected) && typeof filters.selected[column].min === 'number' ? filters.selected[column].min : values['min']} type='min' />
                    <div className='input-group-text no-select'>to</div>
                    <NumberInput key={column+'max'} column={column} value={keyExists(column, filters.selected) && typeof filters.selected[column].max === 'number' ? filters.selected[column].max : values['max']} type='max' />
                  </>
                :
                isFullArray(values) && values.map((value:number|string) => <FilterButton key={column+value} column={column} row={value} />) //'Selected' : '‍'
              }
            </div>
          </div>
        )}
      </ModalBody>
    </Modal>

  return (
    <div className='btn-group w-100'>
      {isFullObject(filters) && <FilterModal />}
      <button className='btn btn-outline-dark connect-top connect-bottom' onClick={toggleModal}
        onMouseEnter={() => setFilterColor('white')} onMouseLeave={() => setFilterColor('black')}>
        {capitalise(type)} Filter <Funnel filterColor={filterColor} />
      </button>
    </div>
  )
}