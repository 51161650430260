import xhr from '../../ajax/xhr';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useLogin = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (user:{ name:string, password:string }) => {
      const params = 'username=' + user.name + '&password=' + user.password;
      return xhr(params, 'authentication/login');
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['sites']
      });
      queryClient.invalidateQueries({
        queryKey: ['check_logged_in']
      });
    }
  });
}
  