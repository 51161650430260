import Cog from 'img/Cog';
import './Main.css';
import React, { useState } from 'react';
import 'reactstrap';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

export default function Footer() {

    const [modal, setModal] = useState(false);
    const toggleModal = () => setModal(!modal);

    const PrivacyModal = () =>
        <Modal isOpen={modal} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>Privacy Statement</ModalHeader>
            <ModalBody>
                <div className='no-select'>
                    <p className='mb-3'>We collect almost no personal data, only your ip address will be logged to protect against spam. User accounts are not bound to any personally identifiable information. The following information is stored against each user account on the server:</p>
                    <ul>
                        <li>Login details.</li>
                        <li>Sites accessible by the user.</li>
                        <li>User settings (available to view in app under the <Cog primaryColor='black' overideColor='black' size={20} /> symbol).</li>
                    </ul>
                    <br/>
                    <p className='mb-3'>We use only one cookie, SWE-Link-PHP, which is an identification string to preserve authentication to the data server between page refreshes.</p>
                </div>
            </ModalBody>
        </Modal>

    return (
        <footer className='border-top fixed-bottom p-3 text-muted no-select'>
            <PrivacyModal />
            <div className='container footer-text'>
                &copy; {(new Date().getFullYear())} | { window.innerWidth > 400 ? <span>Southern Water Engineering</span> : <span>SWE</span> } 
                { global.config.dev && <span> | v{global.config.ver}</span> }
                <span onClick={toggleModal} className='link-dark'> | Privacy Statement</span>
            </div>
        </footer>
    );
}