import React, { useMemo } from 'react';
import { CSVLink } from 'react-csv';
import { capitalise } from 'funcs/capitalise';
import { isFullArray } from 'funcs/isFullArray';
import whiteIfDark from 'funcs/whiteIfDark';
import Filter from 'elements/controls/Filter';
import { primaryColorButton } from 'style/primaryColorButton';
import { isFullObject } from 'funcs/isFullObject';
import { useSiteName } from 'hooks/state/useSiteName';
import { usePrimaryColor } from 'hooks/state/usePrimaryColor';
import { filtersType } from 'types/filtersType';
import { sortType } from 'types/sortType';

// TODO - FIX CSV file to be name dependent on filters/sort
// TODO - FIX Remove color from CSV download

type dataType = Record<string, string | number | Date>[];
type dataTableType = {
  data:dataType,
  summary?:boolean,
  rowsToShow?:{now:number, max:number, update:Function},
  filters?:filtersType,
  sort?:sortType,
  interval?:simpleStateType
}

export const DataTable = (props:dataTableType) => {

  const rowsToShow = useMemo(() => (isFullObject(props.rowsToShow)) ? props.rowsToShow : {now:5, max:5, update:()=>{}} ,[props.rowsToShow]);
  const siteName = useSiteName();
  const data = props.data;
  const sort = props.sort;
  const columnHeaders = useMemo(() => isFullArray(data) && Object.keys(data[0]), [data]);
  const primaryColor = usePrimaryColor();

  const DownloadButton = () =>
    <div className='btn-group w-100'>
      <CSVLink className='btn btn-outline-dark border-top-0 w-100 connect-bottom connect-top border-bottom-0'
        style={primaryColorButton(primaryColor, 'black')} data={data}
        filename={(typeof siteName === 'string' ? siteName.replace(' ','-')
          : 'UnknownSite') + '-Table.csv'}>Download</CSVLink>
    </div>

  const TableHeader = () =>
    <thead className={'thead-dark' + (props.summary ? ' thead-rounded' : '')}>
      <tr>
        {isFullObject(columnHeaders) && columnHeaders.map((column: string, i: number) => {
          const isSelected = column === sort?.col;
          return (
          (column !== 'color' && column !== 'id') && 
          <th key={i} className={props.summary ? '' : 'sticky-header'} scope='col'
            style={primaryColorButton(primaryColor, '#000000', isSelected)}
            onClick={() => sort.update(column)}>
            {capitalise(column) + (isSelected ? {asc:' ↑',desc:' ↓'}[sort.dir] : '')}
          </th>
          );
        }
        )}
      </tr>
    </thead>

  const DataRow = (props: { ri: number; row: Record<string, string | number | Date> }) => {
    const color = (props.row.color !== undefined) ? props.row.color as string : '#ffffff';
    return (
      <tr>
        {Object.values(props.row).map((cell, ci) => (columnHeaders[ci] !== 'id') &&
        <DataCell key={props.ri + ci} cell={cell} ci={ci} cellStyle={{ backgroundColor: color, color: whiteIfDark(color) }} />)}
      </tr>
    )
  }

  const DataCell = (props: { ci: number; cell: string | number | Date; cellStyle: Record<string, string>; }) => {
    return columnHeaders[props.ci] !== 'color' && <td className='col' style={props.cellStyle}>
      {props.cell.toString()}
    </td>
  }

  return (
    <>
      { !props.summary &&
      <>
        {isFullArray(data) && <DownloadButton />}
        <Filter type='table' filters={props.filters} interval={props.interval} />
      </>
      }
      <table className='table mb-0 no-select'>
        <TableHeader />
        <tbody>
          {isFullArray(data) && data?.map((row, ri) => <DataRow key={ri} ri={ri} row={row} />)}
        </tbody>
      </table>
      {(isFullArray(data) && rowsToShow.now < rowsToShow.max) &&
        <div className='btn-group w-100'>
          <button className='btn btn-outline-dark w-100 connect-top'
            onClick={() => rowsToShow.update(rowsToShow.now + 20)}>Show More
          </button>
          <button className='btn btn-outline-dark w-100 connect-top'
            onClick={() => rowsToShow.update(rowsToShow.max)}>Show All ({rowsToShow.max})
          </button>
        </div>
      }
    </>
  );
}