import React, { useState } from 'react';
import 'reactstrap';
import '../elements/Main.css';

export default function Bubble(props:{primaryColor:string;}) {
    
    const [color, setColor] = useState('white');

    return (
        <svg xmlns='http://www.w3.org/2000/svg' 
        width='25pt'
        height='25pt'
        viewBox='0 0 48 48'
        onMouseEnter={() => setColor(props.primaryColor)}
        onMouseLeave={() => setColor('white')}>
            <path id='shape0' transform='translate(4.50412217628297, 8.05358308423553)' fill={color} stroke={color} strokeWidth='3' strokeLinecap='square' strokeLinejoin='bevel' d='M4.1625 33.0635C4.87804 31.4717 4.73264 31.1384 5.10976 28.9264C6.4116 22.773 5.17831 25.6915 6.12322 23.5895C2.02757 20.0316 -0.0357518 18.4452 0.000468688 11.4023C0.59234 -0.98719 12.1343 0.204891 19.2621 0.000425545C24.9122 -0.0128165 38.6057 0.210222 38.9479 11.4023C39.6345 22.069 32.2568 27.0359 16.8147 26.3031C10.511 29.7489 10.6871 30.147 4.1625 33.0635Z'/>
            <circle id='shape0' transform='matrix(1.00000003462377 0 0 1.00000003462377 6.35771649275916 39.0694215883094)' r='1.5' cx='2.30890250346853' cy='2.30890250346853' fill={color} fillRule='evenodd' strokeOpacity='0' stroke={color} strokeWidth='0' strokeLinecap='square' strokeLinejoin='bevel'/>
        </svg>
    )
}