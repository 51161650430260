import { siteType } from 'types/siteType';
import xhr from '../../ajax/xhr';
import { useQuery } from '@tanstack/react-query';

export const useSites = () => {
  const { data } = useQuery<siteType[], Error>({
    queryKey: ['sites'],
    queryFn: async () => xhr('', 'get/sites').then(r => (r[0] === '[') ? JSON.parse(r) : r),
    staleTime:Infinity
  });
  return data;
} 