import React from 'react';
import 'reactstrap';
 
export default function Logo(props:{ color: string, ringColor?:string }) {
    
    const color:string = props.color;

    return (
        <svg xmlns='http://www.w3.org/2000/svg'
             width='70px' height='50px'
             viewBox='0 0 1103.76 777.6'>
            <path id='ring'
                transform='matrix(0.999999973457206 0 0 0.999999973457206 19.6708428999071 44.2432497016962)'
                fill={props.ringColor === undefined ? 'white' : props.ringColor} fillRule='evenodd' stroke='none' strokeWidth='0'
                strokeLinecap='square'
                strokeLinejoin='bevel' d='M725.889 294.877L510.969 170.677C465.739 148.216 417.309 130.037
                                374.529 130.717C339.494 132.931 278.997 154.17 253.569 171.757C226.803 182.462 166.523 239.314
                                159.609 291.997C140.653 354.777 160.252 437.676 176.706 459.01C189.158 489.31 249.527 568.144
                                289.929 588.637C367.689 635.257 396.849 643.016 498.009 689.077C504.472 680.321 271.06 780.728
                                108.849 612.397C67.5279 560.022 30.129 505.346 12.628 440.047C-1.55095 387.144 -6.98912 324.388
                                13.4491 253.117C39.4854 176.082 60.9405 133.264 111.828 91.5947C139.127 69.2412 176.139 48.0471
                                229.449 23.7967C325.064 -14.2388 427.304 -6.67869 536.169 46.4767C665.245 107.926 708.229 224.91
                                725.889 294.877Z'/>
            <path id='bottom_wave'
                transform='matrix(0.999999973457206 0 0 0.999999973457206 207.020782945731 408.240031686539)'
                fill={color} fillRule='evenodd' stroke='none' strokeOpacity='0' strokeWidth='0'
                strokeLinecap='square'
                strokeLinejoin='bevel' d='M0.33921 0C-0.625279 16.2385 0.470776 33.1639 3.57921 50.76C12.9528 76.2386
                                24.3046 89.8476 34.8992 108C59.1821 134.69 69.9839 140.196 85.6592 153.36L308.139 289.44C328.659
                                299.045 349.179 305.026 369.699 308.88C397.531 312.394 425.318 316.085 455.019 312.12C486.66 307.907
                                505.74 299.507 526.299 291.6L600.819 246.24L537.099 248.4C499.517 249.463 461.885 245.617 426.219
                                227.52C383.722 212.827 348.157 185.17 307.419 163.44L173.715 64.08C151.322 45.6308 124.265 30.8593
                                79.3949 17.28C38.9185 7.20002 24.0253 5.76005 0.33921 0Z'/>
            <path id='middle_wave'
                transform='matrix(0.999999973457206 0 0 0.999999973457206 204.258889595456 307.581399242409)'
                fill={color} fillRule='evenodd' stroke='none' strokeOpacity='0' strokeWidth='0'
                strokeLinecap='square'
                strokeLinejoin='bevel' d='M3.10111 65.0186C-7.27769 52.8092 10.3727 17.8944 26.8611 4.53863C39.495
                                -4.52923 87.5218 2.01721 112.181 6.69863C155.439 13.5386 163.575 16.5814 187.761 28.9449L267.701
                                72.5786L473.981 210.819C487.255 222.249 495.459 224.843 511.781 232.419C526.032 239.033 555.126
                                250.135 570.617 254.993C597.394 259.519 613.706 262.962 642.402 263.648C676.962 264.403 715.901
                                255.536 750.461 250.779L683.501 291.819C659.209 309.279 630.545 317.87 606.821 320.979C588.477
                                322.536 559.333 323.873 536.981 322.419C504.481 319.78 475.769 316.368 445.181 302.259C427.061
                                292.221 407.141 284.228 387.941 272.019L209.381 150.339C170.959 120.351 130.442 102.089 98.1411
                                90.9386C69.911 80.0687 15.9383 76.3396 3.10111 65.0186Z'/>
            <path id='top_wave'
                transform='matrix(0.999999973457206 0 0 0.999999973457206 241.32139838922 214.151408232493)'
                fill={color} fillRule='evenodd' stroke='none' strokeOpacity='0' strokeWidth='0'
                strokeLinecap='square'
                strokeLinejoin='bevel' d='M0 72.4814C14.7754 57.5646 28.501 44.9622 47.0386 35.6886C70.2575 21.795
                                102.06 6.62508 132.359 1.84864C165.479 -1.84146 198.784 -1.1697 231.719 14.8086L635.639 238.369C653.821
                                247.337 670.386 251.652 702.959 256.729C732.034 260.709 760.746 263.238 787.919 259.609L851.639
                                251.329L790.079 285.889C768.119 297.591 747.239 309.516 723.119 313.969C690.978 321.933 660.485
                                323.304 630.239 323.689C562.971 326.292 512.903 302.581 495.239 293.449C472.025 280.773 424.865
                                253.413 353.759 211.369C296.866 175.754 230.564 134.838 170.159 97.6086C151.799 87.738 153.523
                                87.7863 118.115 77.5726C86.157 71.786 87.9836 69.583 49.3843 70.9541C31.8309 71.7194 7.58352
                                74.5184 0 72.4814Z'/>
        </svg>
    );
}