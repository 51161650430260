import { keyExists } from "funcs/keyExists";
import { useSettings } from "hooks/ajax/useSettings";
import { useEffect } from "react";

export const useFontsize = () => {
  const settings = useSettings('global');
  useEffect(() => {
    keyExists('global', settings) && keyExists('font_size', settings.global) &&
      (document.getElementsByTagName('html')[0].style['font-size'] = 
        (settings.global.font_size > 10 && settings.global.font_size < 40) ? settings.global.font_size + 'px' : 
        (settings.global.font_size < 40) ? '10px' : '40px' );
  }, [settings]);
}