export default function hex2rgb(hex:string):number[] {
    if (typeof hex === 'string' && hex.length === 7 && hex[0] === '#') {
        const rgb = parseInt(hex.substring(1), 16); 
        const r = (rgb >> 16) & 0xff;
        const g = (rgb >>  8) & 0xff;
        const b = (rgb >>  0) & 0xff;
        return [r, g, b];
    } else {
        return [255, 255, 255];
    }
}