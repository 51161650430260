import './Main.css';
import React from 'react';
import 'reactstrap';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import DisplayPage from './pages/Display';
import LoginPage from './pages/Login';
import 'config';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

export default function Main() {

  const router = createBrowserRouter([
    {
      path: '/',
      element: <LoginPage />
    },
    {
      path: '/:site/*',
      element: <DisplayPage />
    }
  ])

  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
