import 'elements/Main.css';
import React from 'react';
import 'reactstrap';
import { BarGraph } from '../graphics/BarGraph';
import { DataTable } from '../graphics/DataTable';
import { capitalise } from 'funcs/capitalise';
import { consumptionType } from 'types/consumptionType';
import { ModulesButton } from 'elements/controls/ModulesButton';
import { isFullArray } from 'funcs/isFullArray';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useSID } from 'hooks/state/useSID';
import { useData } from 'hooks/ajax/useData';
import { useFilters } from 'hooks/ajax/useFilters';
import { prepareFilters } from 'funcs/prepareFilters';
import { useSort } from 'hooks/state/useSort';
import { isFullObject } from 'funcs/isFullObject';
import { useSimpleState } from 'hooks/state/useSimpleState';
import { useSecondaryColor } from 'hooks/state/useSecondaryColor';
import { usePrimaryColor } from 'hooks/state/usePrimaryColor';

export default function ConsumptionsModule(props:{ expanded?: boolean, type:string }) {

  const sid = useSID();

  const filters = useFilters({ sid: sid, table: props.type });
  const sort = useSort(props.type);

  const rowsToShow = useSimpleState(20);
  const interval = useSimpleState('Daily');
  
  const [maxRows, consumptions] = useData({
    sid: sid,
    table: props.type,
    limit: props.expanded ? rowsToShow.value : 30,
    conditions: prepareFilters(filters.selected),
    order: isFullObject(sort) ? JSON.stringify([sort.col, sort.dir]) : `[]`,
    interval: interval.value
  });

  const primaryColor = usePrimaryColor();
  const secondaryColor = useSecondaryColor();

  const location = useLocation();
  const path = location.pathname;
  const navigate = useNavigate();

  const ViewButton = (props: { view: string; }) =>
    <button className='btn btn-outline-dark connect-bottom' onClick={() => navigate(props.view)}>
      {capitalise(props.view)} View
    </button>

  const graphProps = {
    barColor: (props.type === 'irrigation') ? primaryColor : secondaryColor,
    unit: 'm³',
    col: {x:'datetime', y:'consumption'}
  }

  const sharedProps = {
    data: addConsumptionColors(consumptions as consumptionType[]),
    filters: filters,
    sort: sort,
    rowsToShow: { now: rowsToShow.value, max: maxRows, update: rowsToShow.update },
    interval: interval

  }

  function addConsumptionColors(consumptions: consumptionType[]) {
    return isFullArray(consumptions) && consumptions.map(row => ({ ...row, color: row.consumption > 0.5 ? secondaryColor : '#ffffff' }));
  }

  return (
    <>
      { props.expanded ?
        <>
          <div className='btn-group w-100'>
            <ModulesButton />
            <ViewButton view={path.includes('table') ? 'graph' : 'table'} />
          </div>
          {
            <Routes>
              <Route path='table' element={<DataTable {...sharedProps} />} />
              <Route path='graph' element={<BarGraph {...graphProps} {...sharedProps} expanded={true} hasStats={true} />} />
            </Routes>
          }
        </>
        :
        <BarGraph {...graphProps} {...sharedProps} expanded={false} hasStats={true} /> 
      }
    </>
  );
}