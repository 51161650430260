import { useQuery } from "@tanstack/react-query";
import xhr from "../../ajax/xhr";
import { useMemo, useState } from "react";
import { filtersType } from "types/filtersType";
import { keyExists } from "funcs/keyExists";

export const useFilters = (info: {sid: string, table: string}):filtersType => {
  const { data, error } = useQuery<any, Error>({
    queryKey: ['filters', Object.entries(info)],
    queryFn: async () => {
      const r = await xhr(`sid=${info.sid}&table=${info.table}`, 'get/filters');
      if (r[0] === '{' && r.length > 2) {
        return JSON.parse(r);
      } else if (r.length > 0 && global.config.dev) {
        return r;
      } else {
        console.log(r);
        return 'no results returned';
      }
    },
    staleTime: 5*10**6
  });
  if (error && global.config.dev) { console.log(error.message) };
  
  const [selectedFilters, setSelectedFilters] = useState<Record<string, any>>({});
  const updateSelectedFilters = (column:string, row:string, value = 'bool') =>
    setSelectedFilters((prev:Record<string, any>) => 
      ({...prev, [column]: keyExists(column, prev) ? 
        {...prev[column], [row]: (value === 'bool') ? (prev[column][row] ? false : true) : value } : 
        {[row]: (value === 'bool') ? true : value}
      })
    )
  return useMemo(() => ({map: data, selected: selectedFilters, update: updateSelectedFilters}),[data, selectedFilters]);
}