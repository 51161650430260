import { capitalise } from 'funcs/capitalise';
import React from 'react';
import 'reactstrap';
import { useSettings } from 'hooks/ajax/useSettings';
import { useSID } from 'hooks/state/useSID';
import { useLogout } from 'hooks/ajax/useLogout';
import { isFullObject } from 'funcs/isFullObject';
import { useUpdateSettings } from 'hooks/ajax/useUpdateSettings';
import { useSettingsStructure } from 'hooks/ajax/useSettingsStructure';

// TODO - FIX number select submitting early and breaking focus
// TODO - FIX severities control feature

export default function Settings() {

  const sid = useSID();
  const settings = useSettings(sid);
  const settingsStructure = useSettingsStructure();
  const updateSettings = useUpdateSettings();
  const logout = useLogout();

  const selectMap = {
    default_sort_column: ['datetime', 'name', 'asset', 'location'],
    default_sort_direction: ['asc', 'desc']
  }

  const handleSettings = (e:React.FocusEvent<HTMLInputElement, Element>|React.ChangeEvent<HTMLInputElement>, group:string, name:string) => {
    e.preventDefault();
    updateSettings.mutate({ sid: sid, group: group, name: name, value: e.target.value })
  }

  const Setting = (props: { name: string, value: any, group: string }) => {
    // input (color, text, number) select (select) inputs (group of above)
    const type = isFullObject(settingsStructure) ? settingsStructure[props.group][props.name] : 'text';
    return (
      <div className={'my-2 col-lg-4 col-sm-12'}>
        <b className='no-select form-label'>{capitalise(props.name).replace('_', ' ').replace('_', ' ')}</b>
        <br />
        {(['color', 'text', 'number'].includes(type)) ?
          <input type={type} defaultValue={props.value} className={`form-control w-100 border-dark${(type === 'color') ? ' form-control-color' : ''}`}
          onBlur={(e) => (type !== 'color') && handleSettings(e, props.group, props.name) }  
          onChange={(e) => (type === 'color') && handleSettings(e, props.group, props.name) } /> :
          (type === 'dropdown') ?
            <select className='form-select border-dark' id={type} value={props.value}
              onChange={(e) => updateSettings.mutate({ sid: sid, group: props.group, name: props.name, value: e.target.value })}>
              {selectMap[props.name]?.map((x: string) => <option key={x} value={x}>{capitalise(x)}</option>)}
            </select> :
            <>lol</>
        }
      </div>
    )
  }

  const SettingRow = (props: { groupSettings: any; group: string; }) =>
    <>
      <h4 className='no-select'>{capitalise(props.group)}</h4>
      <div className='row mb-5'>
        {Object.entries(props.groupSettings).map(([k, v]) => (k !== 'severities' && <Setting key={k} name={k} value={v} group={props.group} />))}
      </div>
    </>;

  return (
    <>
      <div className='row m-3'>
        <button className='btn btn-dark' onClick={() => logout.mutate()}>Logout</button>
      </div>
      <div className='row m-3'>
        <span className='w-100 text-center no-select fs-6 fw-light'>version {global.config.ver}</span>
      </div>
      <div className='row m-3'>
        {(isFullObject(settings)) && Object.entries(settings).map(([groupName, groupValues]) =>
          <SettingRow key={groupName} groupSettings={groupValues} group={groupName} />)}
      </div>
    </>
  );
}