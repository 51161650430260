import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { isFullArray } from "../../funcs/isFullArray";
import { useIsLoggedIn } from "hooks/ajax/useIsLoggedIn";
import { useSites } from "hooks/ajax/useSites";

export const useCheckLoggedIn = () => {
  const isLoggedIn = useIsLoggedIn();
  const sites = useSites();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (isLoggedIn.length === 1 && isFullArray(sites)) {
      if (isLoggedIn === '1') {
        (location.pathname === '/') && navigate('/' + sites[0].site.slice(1));
      } else {
        navigate('/');
      }
    }
  },[isLoggedIn, sites, navigate, location.pathname]);
}