import { isFullObject } from "./isFullObject";
import { keyExists } from "./keyExists";

export const prepareFilters = (filters:Record<string, Record<string, boolean|number|string|Date>>) => {
  let results = [];
  const values:Record<string, any[]> = {};
  for (let [column, rows] of Object.entries(filters)) {
    for (let [row, value] of Object.entries(rows)) {
      if (['max', 'min'].includes(row)) {
        results.push([column, row === 'min' ? '>' : '<', value])
      } else if (value) {
        keyExists(column, values) ? values[column].push(row) : values[column] = [row];
      }
    }
  } 
  return isFullObject(filters) ? JSON.stringify([...results, ...Object.entries(values).map(([column, rows]) => [column, 'in', `(${rows.join(',')}`])]) : `[]`;
}