import { useQuery } from "@tanstack/react-query";
import xhr from "../../ajax/xhr";

export const useModules = (sid: string) => {
  const { data } = useQuery<string[], Error>({
    queryKey: ['modules', sid],
    queryFn: async () => {
      const params = 'sid=' + sid;
      return xhr(params, 'get/modules').then(r => {
        let output = (r[0] === '[') ? JSON.parse(r) : r;
        return output;
      });
    },
    staleTime: Infinity
  });
  return data;
}