import 'elements/Main.css';
import React, { useLayoutEffect, useMemo, useState } from 'react';
import 'reactstrap';
import { DataTable } from '../graphics/DataTable';
import { isFullArray } from 'funcs/isFullArray';
import { ModulesButton } from 'elements/controls/ModulesButton';
import { isFullObject } from 'funcs/isFullObject';
import { useSID } from 'hooks/state/useSID';
import { useSettings } from 'hooks/ajax/useSettings';
import { useData } from 'hooks/ajax/useData';
import { alarmType } from 'types/alarmType';
import hex2rgb from 'funcs/hex2rgb';
import whiteIfDark from 'funcs/whiteIfDark';
import { useFilters } from 'hooks/ajax/useFilters';
import { prepareFilters } from 'funcs/prepareFilters';
import { useSort } from 'hooks/state/useSort';
import { useSimpleState } from 'hooks/state/useSimpleState';

// TODO - ADD alarms archive feature
// TODO - FIX severities colors on table

export default function AlarmsModule(props: { expanded?: boolean }) {

  const sid = useSID();

  const settings = useSettings(sid);
  const rowsToShow = useSimpleState(10);
  const filters = useFilters({ sid: sid, table: 'alarms' });
  const sort = useSort('alarms');

  const [maxRows, data] = useData({ 
    sid: sid, 
    table: 'alarms', 
    limit: ((props.expanded || !isFullObject(settings)) ? rowsToShow.value : settings.alarms.summary_amount),
    conditions: prepareFilters(filters.selected),
    order: isFullObject(sort) ? JSON.stringify([sort.col, sort.dir]) : `[]`
  });
  const [alarms, setAlarms] = useState<alarmType[]>([]);
  const [, summary] = useData({ sid: sid, table: 'alarms', columns: '["severity", "count(severity)"]', group: 'severity' }) as [number, { severity: number, 'count(severity)': number }[]];

  const max = (arr: Record<string, number>[], key: string) => {
    let _max: number = 0;
    for (let item of arr) {
      _max = (item[key] > _max) ? item[key] : _max;
    }
    return _max;
  }

  const severityColor = useMemo(() => {
    if (isFullObject(settings) && isFullArray(summary)) {
      return hex2rgb(settings.alarms.high_severity);
    } else {
      return hex2rgb(global.config.defaultColor);
    }
  }, [settings, summary]);

  useLayoutEffect(() => {
    if (isFullArray(data)) {
      let _alarms = [];
      for (let row of data as alarmType[]) {
        _alarms.push({
          id: row.id,
          datetime: row.datetime,
          name: row.name,
          asset: row.asset,
          location: row.location,
          //color: rgb2hex(severityColor.map(c => -(c * row.severity - 255)))
        });
      }
      setAlarms(_alarms);
    }
  }, [data, severityColor])

  const Severity = (props: { n: number, i: number }) => {
    const _alpha = isFullArray(summary) ? props.i/max(summary, 'severity') : 1;
    const _color =  (_alpha === 0) ? 'white' : `rgba(${severityColor.toString()},${_alpha})`;
    return (
      <button className='btn connect-top' style={{ background: _color, color: whiteIfDark(severityColor, _alpha) }}>
        {props.n}
      </button>
    );
  }

  return (
    <>
      {props.expanded ?
        <>
          <div className='btn-group w-100'><ModulesButton /></div>
          {isFullArray(alarms) && <DataTable data={alarms} rowsToShow={{ now: rowsToShow.value, max: maxRows, update: rowsToShow.update }} filters={filters} sort={sort} />}
        </>
        :
        <div className='no-select no-hover'>
          {isFullArray(alarms) && <DataTable data={alarms} summary={true} />}
          <div className='btn-group w-100'>
            {isFullArray(summary) && summary?.map((sev, i) => (sev['count(severity)'] > 0) && <Severity key={i} i={sev.severity} n={sev['count(severity)']} />)}
          </div>
        </div>
      }
    </>
  );
}