import React from 'react';
import 'reactstrap';

export default function User() {

    const color = 'white';
    
    return (
        <svg xmlns='http://www.w3.org/2000/svg' 
            width='48pt'
            height='48pt'
            viewBox='0 0 48 48'>
            <circle id='head' transform='matrix(0.387428146809214 0 0 0.387428146809214 16.0034824247246 7.2630991885509)' 
                r='20.64' cx='20.64' cy='20.64' fill={color} fillRule='evenodd' strokeOpacity='0' stroke='none' strokeWidth='0' 
                strokeLinecap='square' strokeLinejoin='bevel'/>
            <path id='body' transform='matrix(0.387428146809214 0 0 0.387428146809214 8.0999488563979 21.2550376635065)' 
                fill={color} fillRule='evenodd' strokeOpacity='0' stroke='none' strokeWidth='0' strokeLinecap='square' 
                strokeLinejoin='bevel' d='M0.00124603 50.2851C27.3608 50.029 54.7204 49.7729 82.08 49.5168C81.7455 22.9802 71.1359 
                -1.9867 38.5151 0.125073C9.63041 -1.05633 -0.127039 21.6515 0.00124603 50.2851'/>
        </svg>
    )
}