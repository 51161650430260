import { useSettings } from "hooks/ajax/useSettings";
import { useState } from "react"
import { useSID } from "./useSID";
import { isFullObject } from "funcs/isFullObject";

export const useSort = (module:string) => {
  const settings = useSettings(useSID());
  const [sort, setSort] = useState(isFullObject(settings) && module === 'alarms' ? 
    {col: settings.alarms.default_sort_column, dir: settings.alarms.default_sort_direction} : 
    {col:'datetime', dir:'desc'}
  );
  const updateSort = (col:string, dir:string = 'asc') => setSort({col: col, dir: (sort.col === col) ? ((sort.dir === 'asc') ? 'desc' : 'asc') : dir});
  return { col: sort.col, dir: sort.dir, update: updateSort };
}