import { useQuery } from "@tanstack/react-query";
import { settingsType } from "types/settingsType";
import xhr from "../../ajax/xhr";

export const useSettingsStructure = () => {
  // this is separated from settings call as it's only used in Settings page
  const {data} = useQuery<settingsType, Error>({
    queryKey: ['settingsStructure'],
    queryFn: async () => {
      return await xhr('', 'get/settingsStructure').then(r => JSON.parse(r));
    },
    staleTime:Infinity
  });
  return data;
}