export default function xhr(params:string, action:string) {
    return new Promise<string>(function (resolve, reject) {
        let xhr = new XMLHttpRequest();
        xhr.onload = function () {
            if (this.readyState === 4 && this.status === 200) {
                resolve(this.responseText);
            } else {
                reject(this.status);
            }
        }
        xhr.withCredentials = true;
        const server:string = (window.location.hostname === 'localhost' ? 'http://localhost:420/' : 'https://'+(global.config.dev ? 'dev.' : '')+'data.swe.co.nz/')+action+'.php';
        xhr.open('POST', server, true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.send(params);
    })
}