import 'elements/Main.css';
import React, {useEffect, useState} from 'react';
import 'reactstrap';
import {PieChart, Pie, Cell, ResponsiveContainer} from 'recharts';
import moment from 'moment';


export default function SourcesModule(props) {

    ///////////////
    //// STATE ////
    ///////////////

    //// Pie ////
    const [startDate, setStartDate] = useState<string|number>(-1);
    const [endDate, setEndDate] = useState<string|number>(-1);
    const [expandedData, setExpandedData] = useState([]);
    const [overviewData, setOverviewData] = useState([]);


    //// Pie Stats Assembler /////
    useEffect(() => {

        function sumArray(data:any) {
            let newArray = [];
            while (data.length > 0) { // Reduces array of hourly consumptions to a single total consumption for each source
                const source = data[0].source;
                let consumption = 0;
                for (let piece of data.filter(x => x.source === source)) {
                    consumption += piece.consumption;
                }
                data = data.filter(x => x.source !== source);
                newArray.push({source: source, consumption: consumption});
            }
            return newArray
        }

        if (props.sourcesData.length > 1 ) {
            let tempData = props.sourcesData
            if (startDate !== -1) {tempData = tempData.filter(x => moment(x.datetime).format('YYYY-MM-DD') >= moment(startDate).format('YYYY-MM-DD'))}
            if (endDate !== -1) {tempData = tempData.filter(x => moment(x.datetime).format('YYYY-MM-DD') <= moment(endDate).format('YYYY-MM-DD'))}

            setExpandedData(sumArray(tempData));
            setOverviewData(sumArray(props.sourcesData.filter(x =>  moment(x.datetime).format('YYYY-MM-DD') === moment(props.sourcesData[0].datetime).format('YYYY-MM-DD'))));
        }
    },[endDate, props.sourcesData, startDate])




    ////////////////////
    //// COMPONENTS ////
    ////////////////////


    //// Pie Chart shared by overview and
    const SourcesPie = (props) => {

        const colours = [['black','white'],['grey','black'],['lightgrey','black']]; // [background, text color]

        const pieLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, name, index, value}) => {
            const radius = innerRadius + (outerRadius - innerRadius) * 0.45;
            const x = cx + radius * Math.cos(-midAngle *  Math.PI / 180);
            const y = cy + radius * Math.sin(-midAngle *  Math.PI / 180);
            return (
                <text fill={colours[index][1]} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline='central'>
                    <tspan x={x} y={y-10}>{name.charAt(0).toUpperCase() + name.slice(1)}</tspan>
                    <tspan x={x} y={y+10}>{value} m&sup3;</tspan>
                </text>
            );
        };

        return (
            <div className='h-100'>
                <div className='row no-select mt-2'>
                    <div className='col p-0'>
                        <ResponsiveContainer className='p-0' minWidth={100} minHeight={200}>
                            <PieChart id='A'>
                                {props.expanded && <rect width='100%' height='100%' fill='white'/>}
                                <Pie data={props.data} cx='50%' cy='50%' outerRadius='100%' nameKey='source'
                                     label={pieLabel} labelLine={false} dataKey='consumption' animationBegin={0} animationDuration={700}>
                                    {props.data.map((x, i) => (<Cell key={'cell-'+i} fill={colours[i][0]} />))}
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            {props.expanded &&
            <div className='row mt-2 p-0'>
                <div className='input-group'>
                    <input className='form-control col-4' type='date' value={startDate} onChange={e => {if (Number(moment(e.target.value).format('Y')) > 2010) {setStartDate(e.target.value)}}} />
                    <input className='form-control col-4' type='date' value={endDate} onChange={e => {if (Number(moment(e.target.value).format('Y')) > 2010) {setEndDate(e.target.value)}}} />
                    <button className='btn btn-success col-4' onClick={() => props.downloadGraph('A', 'Pie')}>Download</button>
                </div>
            </div>
            }
        </div>
        );
    }



    ////////////////
    //// RETURN ////
    ////////////////


    //// Return statement for SourcesModule ////
    return (
        <>
            {props.expanded ?
            <>
                <button className='btn btn-outline-dark w-100 connect-bottom' onClick={() => props.updateDisplayState('overview')}>Modules</button>
                <SourcesPie expanded={true} data={expandedData} DownloadGraph={props.downloadGraph}/>
            </>
            :
            <SourcesPie data={overviewData} />
            }
        </>
    );
}