import { useMutation, useQueryClient } from '@tanstack/react-query';
import xhr from 'ajax/xhr';

export const useLogout = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => xhr('','authentication/logout'),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['check_logged_in']});
      console.log('worked');
    }
  });
}