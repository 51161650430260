import { useQuery } from "@tanstack/react-query";
import { settingsType } from "types/settingsType";
import xhr from "../../ajax/xhr";

export const useSettings = (type: string) => {
  const {data} = useQuery<settingsType, Error>({
    queryKey: ['settings', type],
    queryFn: async () => {
      const params = 'sid=' + type;
      return await xhr(params, 'get/settings').then(r => JSON.parse(r));
    },
    staleTime:Infinity
  });
  return data;
}