import { useQuery } from "@tanstack/react-query";
import xhr from "../../ajax/xhr";

export const useIsLoggedIn = () => {
  const { data } = useQuery<string, Error>({
    queryKey: ['check_logged_in'],
    queryFn: () => xhr('', 'authentication/checkLoggedIn'),
    staleTime:5000,
    initialData:''
  });
  return data;
}