import { useMemo } from "react";
import { isFullArray } from "../../funcs/isFullArray";
import { useSID } from "./useSID";
import { getFromObjectArray } from "../../funcs/getFromObjectArray";
import { useSites } from "hooks/ajax/useSites";

export const useSiteName = () => {
  const sid = useSID();
  const sites = useSites();
  return useMemo(() => isFullArray(sites) ? getFromObjectArray(sites, 'site', sid, 'name') : '',[sites, sid]);
}