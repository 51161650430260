import React, { useState } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import User from 'img/User';
import Key from 'img/Key';
import { useLogin } from 'hooks/ajax/useLogin';
import { useCheckLoggedIn } from 'hooks/helper/useCheckLoggedIn';

export default function LoginPage() {

  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const loginCheck = useLogin(); // mutation for login form submit
  useCheckLoggedIn(); // query to auto skip login screen if logged in

  const handleLoginSubmit = (e:React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    loginCheck.mutate({ name: username, password: password });
  }

  function testFunc() { }

  return (
    <div className='d-flex center' id='top'>
      {global.config.dev && <button onClick={() => testFunc()}>Test</button>}
      <Header />
      <form className='d-block m-auto text-center' onSubmit={handleLoginSubmit}>
        <div className='form-group'>
          <div className='form-row justify-content-center'>
            <div className='input-group'>
              <span className='input-group-text no-select login-icons'><User /></span>
              <input type='text' onChange={(e) => setUsername(e.target.value)} value={username} name='username' className='form-control' required />
            </div>
          </div>
          <div className='form-row mt-2 justify-content-center'>
            <div className='input-group'>
              <span className='input-group-text no-select login-icons'><Key /></span>
              <input type='password' onChange={(e) => setPassword(e.target.value)} value={password} name='password' className='form-control' required />
            </div>
          </div>
          <div className='form-row justify-content-center mt-2'>
            <button type='submit' className='btn btn-dark'>Login</button>
          </div>
          <span className='text-danger'>{(loginCheck.data !== 'ok') && loginCheck.data}</span>
        </div>
      </form>
      <Footer />
    </div>
  );
}