import 'elements/Main.css';
import React from 'react';
import 'reactstrap';
import Header from 'elements/Header';
import { Modules } from './Modules';
import AlarmsModule from 'elements/pages/Alarms';
import SourcesModule from './Sources';
import { siteType } from 'types/siteType';
import { primaryColorButton } from 'style/primaryColorButton';
import { isFullObject } from 'funcs/isFullObject';
import Settings from './Settings';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useCheckLoggedIn } from 'hooks/helper/useCheckLoggedIn';
import { usePrimaryColor } from 'hooks/state/usePrimaryColor';
import { useSites } from 'hooks/ajax/useSites';
import { useSID } from 'hooks/state/useSID';
import ConsumptionsModule from './Consumptions';
import { useFontsize } from 'hooks/helper/useFontsize';

// TODO - ADD NoMatch here as structure doesn't allow it to work with default setup

export default function DisplayPage() {

  useCheckLoggedIn();
  useFontsize();

  const primaryColor = usePrimaryColor();

  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const returnTrailingPath = () => {
    const trails: string[] = path.split('/').slice(2);
    return trails.length > 0 ? '/' + trails.reduce((prev, trail) => prev + '/' + trail) : '';
  }

  const sites = useSites();
  const sid = useSID();

  const SiteButton = (props: { i: number; selected: boolean; site: siteType | { name: string }; }) => {

    const selected = props.selected;
    const site = props.site as siteType;
    const name = site.name;

    let classStyle = 'col btn mb-1 mx-1 w-100 ' + (selected ? 'no-hover' : 'btn-outline-dark');
    props.i === 0 && (classStyle += ' ms-1');
    (isFullObject(sites) && props.i === sites.length - 1) && (classStyle += ' me-1');

    return (
      <button className={classStyle} style={selected ? primaryColorButton(primaryColor, 'black') : {}}
        onClick={() => {
          if (name === 'Global') {
            navigate('/global/settings');
          } else {
            navigate('/' + site.site.slice(1) + returnTrailingPath());
          }
        }}>
        {name}
      </button>
    )
  }

  return (
    <div className='d-flex'>
      <Header />
        <div className='w-100 main-body m-auto'>
          <div className='row m-1'>
            {path.includes('settings') && <SiteButton key='global' i={-1} selected={path.includes('global')} site={{ name: 'Global' }} />}
            {sites?.map((site, i) =>
              <SiteButton key={site.site} i={i} selected={sid === site.site && !path.includes('global')} site={site} />)}
          </div>
          <div className='p-1 col w-100'>
            <Routes>
              <Route path='alarms' element={<AlarmsModule expanded={true} />} />
              <Route path='irrigation/*' element={<ConsumptionsModule expanded={true} type='irrigation' />} />
              <Route path='fertigation/*' element={<ConsumptionsModule expanded={true} type='fertigation' />} />
              <Route path='sources' element={<SourcesModule expanded={true} />} />
              <Route path='settings' element={<Settings />} />
              <Route path='/' element={<Modules />} />
            </Routes>
          </div>
        </div>
    </div>
  );
}