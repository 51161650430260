import React, { useState } from 'react';
import 'reactstrap';

export default function Cog(props:{ primaryColor:string; overideColor?:string; size?:number}) {

    const [color, setColor] = useState(props.overideColor || 'white'); 
    const strokeWidth = 1;

    return (
        <svg 
            xmlns='http://www.w3.org/2000/svg'
            width={props.size || 25+'pt'}
            height={props.size || 25+'pt'}
            viewBox='0 0 48 48'
            onMouseEnter={() => setColor(props.primaryColor)}
            onMouseLeave={() => setColor(props.overideColor || 'white')}>
            <ellipse id='shape0' transform='translate(12.2478851093742, 12.0781797627223)' rx='11.7521148906258' ry='11.9218202372777' cx='11.7521148906258' cy='11.9218202372777' fill='none' stroke={color} strokeWidth='9.6' strokeLinecap='square' strokeLinejoin='bevel' />
            <path id='shape1' transform='matrix(0.999142129950087 0.0414126086935497 -0.0414126086935497 0.999142129950087 19.4533193975786 2.36776762142434)' fill={color} fillRule='evenodd' strokeOpacity='1' stroke={color} strokeWidth={strokeWidth} strokeLinecap='square' strokeLinejoin='bevel' d='M0 8.92108L9.48 9.13986C8.28669 0.765522 8.10791 1.0743 7.28288 0L1.92018 0.103133C0.64919 1.16915 0.948506 1.38748 0 8.92108' />
            <path id='shape01' transform='matrix(-0.0414126079925038 0.999142113036288 -0.999142113036288 -0.0414126079925038 45.5545740746421 19.4533191038512)' fill={color} fillRule='evenodd' strokeOpacity='1' stroke={color} strokeWidth={strokeWidth} strokeLinecap='square' strokeLinejoin='bevel' d='M0 8.92108L9.48 9.13986C8.28669 0.765522 8.10791 1.0743 7.28288 0L1.92018 0.103133C0.64919 1.16915 0.948506 1.38748 0 8.92108' />
            <path id='shape011' transform='matrix(-0.999142118257123 -0.0414126082088978 0.0414126082088978 -0.999142118257123 28.5466809199067 45.624690682295)' fill={color} fillRule='evenodd' strokeOpacity='1' stroke={color} strokeWidth={strokeWidth} strokeLinecap='square' strokeLinejoin='bevel' d='M0 8.92108L9.48 9.13986C8.28669 0.765522 8.10791 1.0743 7.28288 0L1.92018 0.103133C0.64919 1.16915 0.948506 1.38748 0 8.92108' />
            <path id='shape02' transform='matrix(0.041412608912346 -0.999142135228881 0.999142135228881 0.041412608912346 2.43068968538658 28.5466809971381)' fill={color} fillRule='evenodd' strokeOpacity='1' stroke={color} strokeWidth={strokeWidth} strokeLinecap='square' strokeLinejoin='bevel' d='M0 8.92108L9.48 9.13986C8.28669 0.765522 8.10791 1.0743 7.28288 0L1.92018 0.103133C0.64919 1.16915 0.948506 1.38748 0 8.92108' />
            <path id='shape03' transform='matrix(0.772994927241155 -0.634412166996988 0.634412166996988 0.772994927241155 5.43877751904372 11.4955980432957)' fill={color} fillRule='evenodd' strokeOpacity='1' stroke={color} strokeWidth={strokeWidth} strokeLinecap='square' strokeLinejoin='bevel' d='M0 8.92108L9.48 9.13986C8.28669 0.765522 8.10791 1.0743 7.28288 0L1.92018 0.103133C0.64919 1.16915 0.948506 1.38748 0 8.92108' />
            <path id='shape04' transform='matrix(0.634412139465743 0.772994893695909 -0.772994893695909 0.634412139465743 36.6086239835341 5.32217790852858)' fill={color} fillRule='evenodd' strokeOpacity='1' stroke={color} strokeWidth={strokeWidth} strokeLinecap='square' strokeLinejoin='bevel' d='M0 8.92108L9.48 9.13986C8.28669 0.765522 8.10791 1.0743 7.28288 0L1.92018 0.103133C0.64919 1.16915 0.948506 1.38748 0 8.92108' />
            <path id='shape05' transform='matrix(-0.77299486147307 0.634412113019824 -0.634412113019824 -0.77299486147307 43.0074449375681 36.8314351922726)' fill={color} fillRule='evenodd' strokeOpacity='1' stroke={color} strokeWidth={strokeWidth} strokeLinecap='square' strokeLinejoin='bevel' d='M0 8.92108L9.48 9.13986C8.28669 0.765522 8.10791 1.0743 7.28288 0L1.92018 0.103133C0.64919 1.16915 0.948506 1.38748 0 8.92108' />
            <path id='shape06' transform='matrix(-0.634412113019824 -0.77299486147307 0.77299486147307 -0.634412113019824 11.238477506348 42.8205707354699)' fill={color} fillRule='evenodd' strokeOpacity='1' stroke={color} strokeWidth={strokeWidth} strokeLinecap='square' strokeLinejoin='bevel' d='M0 8.92108L9.48 9.13986C8.28669 0.765522 8.10791 1.0743 7.28288 0L1.92018 0.103133C0.64919 1.16915 0.948506 1.38748 0 8.92108' />
        </svg>
    )
}