import React from 'react';
import 'reactstrap';

export default function Funnel(props:{filterColor:string;}) {
    const color = props.filterColor;
    return (
        <svg 
            xmlns='http://www.w3.org/2000/svg'
            width='20pt'
            height='20pt'
            viewBox='0 0 48 48'>
            <path id='shape0' transform='translate(4.8, 8.03999993999841)' fill={color}stroke={color} strokeWidth='3' strokeLinecap='square' strokeLinejoin='bevel' d='M15.06 18.54L0 0L38.4 0.12L22.8 18.72L23.04 32.16L15.42 36.9Z'/>
        </svg>
    )
}