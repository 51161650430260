import { useQuery } from "@tanstack/react-query";
import xhr from "../../ajax/xhr";
import { isFullArray } from "funcs/isFullArray";

export const useData = (rParams: Record<string, string | number | any[]>) => {
  const { data, error } = useQuery<any, Error>({
    queryKey: ['data', Object.entries(rParams)],
    queryFn: async () => {
      let params = '';
      const keys = Object.keys(rParams);
      for (const [param, value] of Object.entries(rParams)) {
        const i = keys.indexOf(param);
        params += param + '=' + value + (i === keys.length - 1 ? '' : '&');
      }
      const r = await xhr(params, 'get/data');
      if (r[0] === '[' && r.length > 2) {
        return JSON.parse(r);
      } else if (r.length > 0 && global.config.dev) {
        return r;
      } else {
        return 'no results returned';
      }
    },
    staleTime: 5*10**6
  });
  if (error && global.config.dev) { console.log(error.message) };
  return (isFullArray(data)) ? [data[0], data[1]] : [0, 0];
}

