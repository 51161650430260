import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import Main from './elements/Main';
import 'bootstrap/dist/css/bootstrap.min.css'
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import 'config';

// TODO - ADD Basic Unit Tests
// TODO - FIX PWA with service worker

!global.config.dev ? serviceWorkerRegistration.register() : serviceWorkerRegistration.unregister();
reportWebVitals();

const container = document.getElementById('root');
const root = ReactDOMClient.createRoot(container);

root.render(
    global.config.dev ? <React.StrictMode><Main/></React.StrictMode> : <div><Main/></div>
);