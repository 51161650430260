import './Main.css';
import React, { useRef, useState } from 'react';
import 'reactstrap';
import Logo from '../img/Logo';
import { Link, useLocation } from 'react-router-dom';
import Cog from 'img/Cog';
import whiteIfDark from 'funcs/whiteIfDark';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import saveFeedback from 'ajax/saveFeedback';
import Bubble from 'img/Bubble';
import { useIsLoggedIn } from 'hooks/ajax/useIsLoggedIn';
import { usePrimaryColor } from 'hooks/state/usePrimaryColor';

export default function Header() {

  const primaryColor = usePrimaryColor();
  const colored = { background: primaryColor, color: whiteIfDark(primaryColor) };
  const isLoggedIn = useIsLoggedIn();

  const location = useLocation();
  const path = location.pathname;

  const feedback = useRef<string>();
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  const FeedbackModal = () =>
    <Modal isOpen={modal}>
      <ModalHeader toggle={toggleModal}>Feedback Form</ModalHeader>
      <ModalBody>
        <textarea className='form-control' style={{ height: '50vh' }} onChange={(e) => feedback.current = e.target.value} />
      </ModalBody>
      <ModalFooter>
        <button className='btn btn-outline' onClick={() => { toggleModal(); saveFeedback(feedback.current); }} style={colored}>Submit</button>
      </ModalFooter>
    </Modal>

  return (
    <header className='fixed-top'>
      <FeedbackModal />
      <nav className='navbar navbar-expand-sm navbar-toggleModalable-sm box-shadow mb-0'>
        <div className='container-fluid'>
          <div className='d-flex'>
            <Logo color={primaryColor} />
            {path !== '/' ?
              <Link to={(!isLoggedIn) && '/'} className='navbar-brand m-auto no-select text-light mx-2'>SWE Link</Link>
              : <span className='navbar-brand m-auto no-select text-light mx-2'>SWE Link</span>
            }
          </div >
          <div className='d-flex'>
            {path !== '/' && !path.includes('settings') && <div className='mx-2' onClick={toggleModal}><Bubble primaryColor={primaryColor} /></div>}
            {path !== '/' && !path.includes('settings') && <Link className='mx-2' to='settings'><Cog primaryColor={primaryColor} /></Link>}
            {location.pathname.includes('settings') && <Link className='nav-link link-light mx-2' to={path.includes('global') ? '/' : '/' + path.split('/')[1]}><button className='btn btn-light' style={colored}>Display</button></Link>}
          </div>
        </div>
      </nav>
    </header>
  );
}