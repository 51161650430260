import React from 'react';
import 'reactstrap';

export default function Key() {

    const color = 'white';
    
    return (
        <svg xmlns='http://www.w3.org/2000/svg' 
            width='48pt'
            height='48pt'
            viewBox='0 0 48 48'>
            <ellipse id='ring' transform='matrix(-0.00428776178341676 0.999990807507199 -0.999990807507199 -0.00428776178341676 19.9343713936811 7.40829517974404)' 
                rx='6.57609304461989' ry='6.57609304461988' cx='6.57609304461989' cy='6.57609304461988' fill='none' stroke={color} strokeWidth='7.2' strokeLinecap='square' 
                strokeLinejoin='bevel'/>
            <path id='shaft' transform='matrix(-0.00428776178341676 0.999990807507199 -0.999990807507199 -0.00428776178341676 41.2740872350646 15.7714323749122)' 
                fill={color} fillRule='evenodd' stroke='none' strokeWidth='0.0024' strokeLinecap='square' strokeLinejoin='bevel' 
                d='M0 20.7515L16.5514 0L26.9134 6.90969L24.4179 9.96439L18.9024 6.40057L17.3134 8.43704L21.1335 11.1523L18.7576 14.4L14.6847 11.5766L5.64 23.9915L0.683944 20.8255'/>
        </svg>
    )
}