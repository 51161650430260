import React, { useState } from 'react';
import AlarmsModule from './Alarms';
import SourcesModule from './Sources';
import { Link } from 'react-router-dom';
import { useModules } from 'hooks/ajax/useModules';
import { useSID } from 'hooks/state/useSID';
import ConsumptionsModule from './Consumptions';
import { useSecondaryColor } from 'hooks/state/useSecondaryColor';

export const Modules = () => {

  const sid = useSID();
  const modules = useModules(sid);
  const secondaryColor = useSecondaryColor();

  const ModuleWrapper = (props: { name: string }) => {

    const name = props.name;
    const [isHovered, setIsHovered] = useState(false);

    return (
      <div className='col mx-1' onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
        <div className='card w-100 h-100 border-0' 
        style={(isHovered) ? {background: secondaryColor} : {}}>
          <Link className='card-body text-decoration-none' to={name + (name === 'irrigation' ? '/graph' : '')}>
            {name.includes('alarms') && <AlarmsModule />}
            {name.includes('irrigation') && <ConsumptionsModule type='irrigation' />}
            {name.includes('fertigation') && <ConsumptionsModule type='fertigation' />}
            {name.includes('sources') && <SourcesModule />}
          </Link>
        </div>
      </div>
    )
  }

  if (typeof modules == 'string') { return <>No Modules</> }

  return (
    <>{modules?.map((module) => <ModuleWrapper key={module} name={module} />)}</>
  );
}

